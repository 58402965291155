


























































































































































































































































































































































































































































































import DateInput from "@/components/DateInput.vue";
import MoneyInput from "@/components/MoneyInput.vue";
import Partner from "@/modules/partner/models/Partner";
import PartnerSearch from "@/modules/partner/views/PartnerSearch.vue";
import Vue from "vue";
import { store, TechnicalInvoiceStore } from "../services/TechnicalInvoiceStore";
import {
  TechnicalInvoiceVIRO,
  TechnicalInvoiceVIRI,
  technicalInvoiceVIRODefaultProps,
  technicalInvoiceVIRIDefaultProps,
} from "../models/TechnicalInvoiceModel";
import eventBus from "@/services/eventBus";
import { Prop } from "vue/types/options";
import TechnicalInvoiceApi from "../services/TechnicalInvoiceApi";

// import { TechnicalInvoiceVIRIType } from "../models/TechnicalInvoiceTypeModel";

interface Data {
  store: TechnicalInvoiceStore;
  partnerSearchDialog: boolean;
  detailsVisible: boolean;
  rules: object;
  previousVIRO: TechnicalInvoiceVIRO;
  tInvoice: TechnicalInvoiceVIRO | TechnicalInvoiceVIRI;
}

interface Methods {
  save(): void;
  changePartner(): void;
  onPartnerChange(newPartner: Partner): void;
  onCurrencyChange(): void;
}

interface Computed {
  detailsIcon: string;
  form: Vue & { validate: () => boolean }; // a form validációhóz így importáljuk be
  partnerFullAdress: string;
}

export default Vue.extend<Data, Methods, Computed, Prop<string>>({
  components: {
    MoneyInput,
    DateInput,
    PartnerSearch,
  },
  props: {
    customTitle: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      store,
      partnerSearchDialog: false,
      detailsVisible: false,
      rules: {
        requiredRule: (v: string) => !!v.trim() || "Kötelező megadni",
        requiredSelectRule: (v: number) => v !== 1 || "Kötelező megadni",

        // lengthRule: (v: any) => v.length < 500 || "Túl sok karakter",
      },
      previousVIRO: { ...technicalInvoiceVIRODefaultProps },
      tInvoice: {...technicalInvoiceVIRODefaultProps}
    };
  },

  async beforeMount() {
    if (this.$route.params.invoiceId) {
      try {
        if (this.$route.params.codeFilter === "viro") {
          await this.store.getViro(Number(this.$route.params.invoiceId));
        }
        if (this.$route.params.codeFilter === "viri") {
          await this.store.getViri(Number(this.$route.params.invoiceId));
        }
      } catch (error) {
        eventBus.$emit("error", error);
      }



      this.tInvoice = (this.$route.params.codeFilter === "viro") ? this.store.tInvoiceVIRO : this.store.tInvoiceVIRI;
    }
    // új számla esetén
    else {
      this.tInvoice = (this.$route.params.codeFilter === "viro") ? {...technicalInvoiceVIRODefaultProps} : {...technicalInvoiceVIRIDefaultProps};
      this.tInvoice.partner = this.store.tInvoicePartner.id;
    }

    await this.store.loadPmodes();
    await this.store.loadStatuses();
    await this.store.loadaccGroups();
    await this.store.loadaccStatuses();
    await this.store.loadPartnerRels(this.$route.params.codeFilter);
    await this.store.loadDivitems();
    await this.store.loadCurrencies();
    await this.store.loadCreatorUser(this.tInvoice.userid);

    // meglévő számla esetén
    if (this.$route.params.invoiceId) {
      await this.store.loadAllInvTypes(this.$route.params.codeFilter);
    }
    // új számla esetén
    else {
      if (this.$route.params.codeFilter === "viro") {
        await this.store.loadOpenerInvTypes("viro");
        await this.store.loadAllInvTypes("viri"); // auto viri generáláshoz
      }
      if (this.$route.params.codeFilter === "viri") {
        await this.store.loadOpenerInvTypes("viri");
      }
    }
  },

  destroyed() {
    this.store.resetTInvoice(this.$route.params.codeFilter);
    this.store.resetTInvoicePartner();
  },

  methods: {
    async onCurrencyChange(){
      if (this.tInvoice.invCurrency != "HUF"){
        const rate = await TechnicalInvoiceApi.getRate(this.tInvoice.invCurrency, new Date());
        this.tInvoice.exchangeRate = rate.centralRate;
        this.tInvoice.rateDate = rate.rateDate;
      } else {
        this.tInvoice.exchangeRate = 1;
        this.tInvoice.rateDate = new Date();
      }
    },

    async save() {
      console.log(this.tInvoice);
      if (!this.form.validate()) return; // ha a form validáció false (valamelyik rule elakad) egyből return

      // logikai validáció és segéderror üzenet
      if (this.$route.params.codeFilter === "viro" && !this.store.typeNyitoKimenoIsValid()) {
        return;
      }
      if (this.$route.params.codeFilter === "viri" && !this.store.typeNyitoBejovoIsValid()) {
        return;
      }

      try {
        const resultTInvoiceId = await this.store.saveNewTInvoice(this.tInvoice, this.$route.params.codeFilter);

        if (resultTInvoiceId) {
          this.$router.push(
            `/invoice-browser/technical-invoice/${this.$route.params.codeFilter}/edit/${resultTInvoiceId}`
          );
        } else {
          console.error(resultTInvoiceId);
          console.error("Számla mentése undefined error:");
        }
      } catch (error) {
        console.error("Számla mentése sikertelen! ", error);
      }

      // // automatikus viri számla generálás
      // if (this.store.autoViriCreation) {
      //   try {
      //     const resultTInvoiceId = await this.store.saveNewTInvoice(this.store.autoVIRI, "viri");
      //   } catch (error) {
      //     console.error("Automatikus VIRI számla mentése sikertelen! ", error);
      //   }
      // }

      // // megévő számla mentés ha minden validáción átment
      // if (this.tInvoice.id !== 0) {
      //   eventBus.$emit("error", "Ez még nincs implementálva!");
      // }
    },

    changePartner() {
      this.partnerSearchDialog = !this.partnerSearchDialog;
    },

    onPartnerChange(newPartner) {
      this.store.tInvoicePartner = { ...newPartner };
      this.tInvoice.partner = this.store.tInvoicePartner.id;
      this.partnerSearchDialog = !this.partnerSearchDialog;
      this.detailsVisible = false;
    },
  },

  computed: {
    detailsIcon() {
      return this.detailsVisible ? "fa-angle-double-up" : "fa-angle-double-down";
    },

    form(): Vue & { validate: () => boolean } {
      return this.$refs.form as Vue & { validate: () => boolean }; // boiler plate kód netről, a form validáláshoz
    },

    partnerFullAdress() {
      return `${this.store.tInvoicePartner.postCode || ""} ${this.store.tInvoicePartner.invoiceCity || ""} ${this.store
        .tInvoicePartner.invoiceAddress || ""}`;
    },
  },

  watch: {
    "tInvoice.rectify": function(newRectify) {
      if (!newRectify) {
        this.tInvoice.invoiceRect = null;
      }
    },

    "tInvoice.currNetto": function(newNetto) {
      // módosítjuk a totált a vat és a netto függvényében
      if (!newNetto) {
        this.tInvoice.currTotal = 0;
      } else if (!this.tInvoice.withVat) {
        this.tInvoice.currTotal = newNetto;
        this.tInvoice.currVat = 0;
      } else {
        this.tInvoice.currTotal = newNetto + this.tInvoice.currVat;
      }

      this.tInvoice.netto = Math.round(this.tInvoice.currNetto * this.tInvoice.exchangeRate);
    },

    "tInvoice.withVat": function(newWithVat) {
      // új értek beállítása false esetén
      if (!newWithVat) {
        this.tInvoice.currVat = 0;
      }

      // módosítjuk a totált
      if (newWithVat && this.tInvoice.currNetto) {
        this.tInvoice.currTotal = this.tInvoice.currNetto + this.tInvoice.currVat;
      }
    },

    "tInvoice.currVat": function(newVat) {
      // módosítjuk a totált
      if (this.tInvoice.withVat && this.tInvoice.currNetto) {
        this.tInvoice.currTotal = this.tInvoice.currNetto + newVat;
      }

      this.tInvoice.vat = Math.round(this.tInvoice.currVat * this.tInvoice.exchangeRate);
    },

    "tInvoice.currTotal": function(newTotal) {
      this.tInvoice.total = Math.round(this.tInvoice.currTotal * this.tInvoice.exchangeRate);
    },

    "tInvoice.exchangeRate": function(newTotal) {
      this.tInvoice.total = Math.round(this.tInvoice.currTotal * this.tInvoice.exchangeRate);
      this.tInvoice.vat = Math.round(this.tInvoice.currVat * this.tInvoice.exchangeRate);
      this.tInvoice.netto = Math.round(this.tInvoice.currNetto * this.tInvoice.exchangeRate);
    },

    "tInvoice.invType": function(newValue) {
      if (!this.$route.params.invoiceId && this.$route.params.codeFilter === "viro") {
        const selectedInvType = this.store.VIROInvTypes.find((invType) => invType.id === newValue);
        if (selectedInvType?.isInternalLoan) {
          //this.store.autoViriCreation = true;
        } else {
          //this.store.autoViriCreation = false;
          //this.store.autoVIRI = { ...technicalInvoiceVIRIDefaultProps };
        }
      }
    },

    "store.tInvoiceVIRO": {
      handler: function(newVIRO) {
        if (this.store.autoViriCreation) {
          let VIRIInvTypeId = 1;

          console.log("newVIRO.invType: ", newVIRO.invType);
          console.log("this.previousVIRO.invType: ", this.previousVIRO.invType);

          if (newVIRO.invType !== this.previousVIRO) {
            console.log("asd");
            const invType = this.store.VIRIInvTypes.find((invType) => invType.isInternalLoan === true);

            if (!invType) {
              eventBus.$emit(
                "error",
                "A VIRI számla hitelezés típusa nem található meg a hitelezett cég adatbázisában!"
              );
            } else {
              VIRIInvTypeId = invType.id;
            }
          }

          // propertyk
          this.store.autoVIRI.invNo = "VIRO" + newVIRO.invNo;
          this.store.autoVIRI.partner = this.store.tInvoiceVIRO.partner;
          this.store.autoVIRI.pmode = this.store.tInvoiceVIRO.pmode;
          this.store.autoVIRI.invDate = this.store.tInvoiceVIRO.invDate;
          this.store.autoVIRI.shipDate = this.store.tInvoiceVIRO.shipDate;
          this.store.autoVIRI.pterm = this.store.tInvoiceVIRO.pterm;
          this.store.autoVIRI.netto = this.store.tInvoiceVIRO.netto;
          this.store.autoVIRI.total = this.store.tInvoiceVIRO.total;
          this.store.autoVIRI.vat = this.store.tInvoiceVIRO.vat;
          this.store.autoVIRI.status = 3;
          this.store.autoVIRI.invType = VIRIInvTypeId;
          this.store.autoVIRI.accGroup = 1;
          this.store.autoVIRI.currency = this.store.tInvoiceVIRO.currency;
          this.store.autoVIRI.remark = "";
          this.store.autoVIRI.withVat = this.store.tInvoiceVIRO.withVat;
          this.store.autoVIRI.userid = 2;
          this.store.autoVIRI.accStatus = 2;
          this.store.autoVIRI.rectify = false;
          this.store.autoVIRI.invoiceRect = null;
          this.store.autoVIRI.storno = false;
          this.store.autoVIRI.invoiceSt = null;
          this.store.autoVIRI.partnerRel = 6;
          this.store.autoVIRI.prefix = "";
          this.store.autoVIRI.invoice = 1;
          this.store.autoVIRI.norder = 1;
          this.store.autoVIRI.sorder = 1;
          this.store.autoVIRI.worksheet = 1;
          this.store.autoVIRI.custorder = 1;
          this.store.autoVIRI.virAccType = 1;
          this.store.autoVIRI.divitem = 1;
          this.store.autoVIRI.car = 1;
          this.store.autoVIRI.prcar = 1;
          this.store.autoVIRI.invCurrency = this.store.tInvoiceVIRO.invCurrency;
          this.store.autoVIRI.currNetto = this.store.tInvoiceVIRO.currNetto;
          this.store.autoVIRI.currTotal = this.store.tInvoiceVIRO.currTotal;
          this.store.autoVIRI.currVat = this.store.tInvoiceVIRO.currVat;
          this.store.autoVIRI.exchangeRate = this.store.tInvoiceVIRO.exchangeRate;
          this.store.autoVIRI.rateDate = this.store.tInvoiceVIRO.rateDate;
          this.store.autoVIRI.stfContract = 1;
        }
        this.previousVIRO = JSON.parse(JSON.stringify(newVIRO)); // need to deepcopy this
      },
      deep: true,
      immediate: true,
    },
  },
});
