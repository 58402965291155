





























import HeaderItem from "@/components/HeaderItem.vue";
import Page from "@/components/Page.vue";
// import InvoiceRejectionForm from "@/components/InvoiceRejectionForm.vue";
import Vue from "vue";
import { store, TechnicalInvoiceStore } from "../services/TechnicalInvoiceStore";
import TechnicalInvoiceView from "./TechnicalInvoiceView.vue";
// import TechnicalInvoiceVIRIDisplayView from "./TechnicalInvoiceVIRIDisplayView.vue";

interface Data {
  store: TechnicalInvoiceStore;
}

interface Computed {
  technicalInvoiceTitle: string;
}

export default Vue.extend<Data, {}, Computed>({
  components: { Page, HeaderItem, TechnicalInvoiceView },
  data() {
    return {
      store: store,
    };
  },

  created() {
    this.store.autoViriCreation = false;
  },

  computed: {
    technicalInvoiceTitle() {
      if (this.$route.params.codeFilter === "viro") {
        return "Kimenő technikai számla adatok (VIRO)";
      }
      if (this.$route.params.codeFilter === "viri") {
        return "Bejövő technikai számla adatok (VIRI)";
      }
      throw new Error("Invalid prefix");
    },
  },
});
